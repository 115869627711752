<template>
  <div class="view_consignment_wrapper">
    <section class="consignment_header">
      <i class="closeme adj1" @click="closeModal">X</i>
      <a class="bt_print" @click="printConsignment">
        <i class="material-icons">print</i>
        Print
      </a>
    </section>
    <div style="height:100px;" v-if="loading">
      <loader />
    </div>
    <section class="table_row row1" v-if="!loading">
      <div class="div_33 branchname_adjustment1 branchname_adjustment3">
        <img class="logo_img" src="../../assets/temp_logo_yellow.png" style="border:2px solid #000" />
        <div class="branchname_adjustment2">
          <header class="small_header">Service</header>
          <div class="small_body">{{ getSelectedServices }}</div>
        </div>
      </div>
      <div class="div_33">
        <div class="middle_div">
          <div class="double_contents sur_border_adj">
            <header class="small_header">DATE</header>
            <div class="small_body" style="border-bottom:0">{{courier.created_at}}</div>
          </div>
          <div class="double_contents">
            <header class="small_header">PIECES</header>
            <div class="small_body"  style="border-bottom:0">{{ getPackagesQty }}</div>
            <header class="small_header">WEIGHT</header>
            <div class="small_body" >{{ getPackagesWeight }}</div>
          </div>
        </div>
      </div>
      <div class="div_33">
        <header class="small_header">TRACKING NUMBER</header>
        <div class="small_body small_body_2 bar_container">
          <barcode :value="courier.booking_prefix + courier.booking_id" tag="img"></barcode>
        </div>
      </div>
    </section>
    <section class="table_row row2" v-if="!loading">
      <div class="div_50">
        <header class="small_header">SENDER</header>
        <div class="small_body">
          <div class="single_row">
            <div>FROM: {{courier.pickup_name}}</div>
            <div>SHIPPER REF:{{courier.reference_no}}</div>
            <div>{{ courier.custom_code }}: {{ courier.custom_code_value }}</div>
            <!-- <div v-if="courier.matter_number">MATTER NUMBER: {{courier.matter_number.matter_number}}</div>
            <div v-if="courier.gl_code">GL CODE: {{`${courier.gl_code.office}-${courier.gl_code.entity}-${courier.gl_code.department}-${courier.gl_code.section}-000`}}</div> -->
          </div>
        </div>
        <div class="small_body address">
          <div>{{courier.pickup_branch}}</div>
          {{courier.pickup_desk ? 'Desk# ' + courier.pickup_desk + ', ':""}}{{courier.pickup_floor ? 'Floor# ' + courier.pickup_floor + ', ':""}}{{courier.pickup_address_1}}, {{courier.pickup_address_2}},{{courier.pickup_postal_code}},{{courier.pickup_city}},{{courier.pickup_country}}
          <div class="comapny-name">
            <div>COMPANY NAME : {{courier.pickup_company}}</div>
          </div>
        </div>
        <div class="small_body">
          <div>BOOKED BY : {{courier.requester_name}}</div>
          <div>PHONE NUMBER :{{courier.requester_phone}}</div>
        </div>
      </div>
      <div class="div_50">
        <header class="small_header">RECEIVER</header>
        <div class="small_body">
          <div class="single_row">
            <div>TO:{{courier.delivery_name}}</div>
            <div></div>
          </div>
        </div>
        <div class="small_body address">
          <div>{{courier.delivery_branch}}</div>
          {{courier.delivery_desk ? 'Desk# ' + courier.delivery_desk + ', ':""}}{{courier.delivery_floor ? 'Floor# ' + courier.delivery_floor + ', ':""}}{{courier.delivery_address_1 ? courier.delivery_address_1 + ', ':""}}{{courier.delivery_address_2 ? courier.delivery_address_2 + ", ":""}}{{courier.delivery_postal_code ? courier.delivery_postal_code + ', ' : ""}}{{courier.delivery_city ? courier.delivery_city + ', ':""}}{{courier.delivery_state ? courier.delivery_state + ', ':""}}{{courier.delivery_country ? courier.delivery_country + ', ':""}}{{courier.delivery_email}}
          <div class="comapny-name">
            <div>COMPANY NAME : {{courier.delivery_company}}</div>
          </div>
        </div>
        <div
          class="small_body"
        >P.O BOX ADDRESS WILL REQUIRE RECIPIENT'S PHONE NUMBER {{courier.delivery_phone}}</div>
      </div>
    </section>
    <section class="table_row row3" v-if="!loading">
      <div class="div_33">
        <header class="small_header">DESCRIPTION OF CONTENTS</header>
        <div class="small_body">
          <div class="single_row">{{courier.goods_description || 'NA' }}</div>
        </div>
      </div>
      <div class="div_33">
        <header class="small_header">VALUE FOR CUSTOMS</header>
        <div class="small_body"></div>
      </div>
      <div class="div_33">
        <header class="small_header">SPECIAL  INSTRUCTIONS</header>
        <div class="small_body">{{courier.pickup_instructions}}</div>
      </div>
    </section>
    <section class="table_row row4" v-if="!loading">
      <div class="full_width">
        <header class="small_header" style="border-bottom:2px solid #000">RECEIVED BY</header>
      </div>
      <div class="full_width flex">
        <div class="div_25">
          <div class="small_body">SIGN:</div>
        </div>
        <div class="div_25">
          <div class="small_body">PRINT NAME:</div>
        </div>
        <div class="div_25">
          <div class="small_body">DATE:</div>
        </div>
        <div class="div_25">
          <div class="small_body">TIME:</div>
        </div>
      </div>
    </section>
    <footer v-if="!loading">
      THIS IS A NON-NEGOTIABLE AWB ISSUED SUBJECT TO THE COMPANY'S STANDARD TRADING CONDITIONS,
      A COPY OF WHICH IS AVAILABLE ON REQUEST
      <p
        class="adj_footer"
      >Contact :&nbsp; E-mail: info@dbsa.asia &nbsp;&nbsp;|&nbsp;&nbsp; Web: www.dbsa.asia</p>
    </footer>
  </div>
</template>
<script>
export default {
  name: "ViewConsignment",
  data() {
    return {
      courier: {},
      loading: true
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get("/api/courierbooking/fetchbookingiddetails/" + this.booking_id)
      .then(response => {
        console.log(response);
        this.courier = response.data.booking_details;
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
      });
  },
  computed: {
    getPackagesQty: function() {
      return (this.courier.courier_items && this.courier.courier_items.length > 0) ? this.courier.courier_items.length : ' - ';
    },
    getPackagesWeight: function() {
      return (this.courier.courier_items && this.courier.courier_items.length > 0)  ? this.courier.courier_items.reduce((total, pkg) => total + pkg.item_weight, 0) : ' - ';
    },
    getSelectedServices: function() {

      const { user_selected_services, delivery_service, provider_name } = this.courier;

      if(user_selected_services.length > 0) {
        const [selected_services] = user_selected_services;

        if(selected_services) {

          const [servicenames] = selected_services.servicenames;

          return `${(servicenames) ? servicenames.name : ''} - ${selected_services.name} (${selected_services.priority})`
        }
      }

      if(delivery_service) {
        return `${delivery_service} - ${provider_name.name}`;
      }

      return '';
				
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    printConsignment() {
      window.print();
    }
  },
  props: ["booking_id", "booking_prefix"]
};
</script>
<style lang="scss">
$theme-color: #06A5ED;
.view_consignment_wrapper {
  border: 10px solid #fff;
  section.table_row {
    padding: 2px 0;
    display: flex;
    &.row1 {
      height: 140px;
      .logo_img {
        width: 100%;
        height: auto;
        display: block;
        &.branchname_adjustment3 {
          height: 65px;
        }
      }
      .middle_div {
        display: flex;
        height: 100%;
        flex-shrink: 2;
        .double_contents {
          width: 50%;
          height: 100%;
          margin: 0 2px 0 0;
          &:last-child {
            margin: 0 0 0 2px;
          }
        }
      }
      header.small_header {
        background: #fff;
        color: #000;
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        text-align-last: center;
        border: 2px solid #000;
        border-bottom: 0;
      }
      .small_body {
        background: #fff;
        color: #000;
        height: 41px;
        padding: 0 10px;
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        border: 2px solid #000;
        border-top: 0;
        font-size: 15px;
        img {
          width: 100%;
          height: 110px;
          padding: 5px;
        }
      }
      .small_body_2 {
        height: 105px;
      }
    }
    &.row2 {
      height: 250px;
      header.small_header {
        background: #fff;
        color: #000;
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        text-align: left;
        border: 2px solid #000;
        border-bottom: 0;
      }
      .small_body {
        background: #fff;
        color: #000;
        height: 60px;
        padding: 10px;
        text-align: left;
        border: 2px solid #000;
        border-top: 0;
        font-size: 15px;
        .single_row {
          height: 24px;
          div {
            display: inline-block;
            padding-right: 20px;
            font-weight: bold;
          }
        }
        &.address {
          font-weight: normal;
          height: 102px;
        }
      }
    }
    &.row3 {
      height: 110px;
      header.small_header {
        background: #fff;
        color: #000;
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        text-align-last: left;
        border: 2px solid #000;
        border-bottom: 0;
      }
      .small_body {
        background: #fff;
        color: #000;
        height: 82px;
        padding: 10px;
        display: flex;

        border: 2px solid #000;
        border-top: 0;
        font-size: 15px;
        .single_row {
          height: 24px;
          div {
            display: inline-block;
            padding-right: 20px;
            font-weight: bold;
          }
        }
        &.theme {
          background: #fff;
        }
      }
    }
    &.row4 {
      height: 110px;
      display: block;

      border-right: 2px solid #000;

      .full_width {
        width: 100%;
      }
      .full_width.flex {
        display: flex;
      }
      header.small_header {
        background: #fff;
        color: #000;
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        text-align-last: left;
        border: 2px solid #000;
        border-bottom: 0;
      }
      .small_body {
        background: #fff;
        color: #000;
        height: 82px;
        padding: 10px;
        display: flex;
        border: 2px solid #000;
        border-right: 0;
        border-top: 0;
        font-size: 15px;
      }
    }
    .row_footer {
      padding: 10px 0;
      font-size: 13px;
      text-align: center;
    }
    .div_33 {
      width: 33%;
      height: 100%;
      margin: 0 2px;
    }
    .div_50 {
      width: 50%;
      height: 100%;
      margin: 0 2px;
    }
    .div_25 {
      width: 25%;
      height: 100%;
      margin: 0;
      background: pink;
    }
  }
  footer {
    font-size: 11px;
    padding: 10px 0;
  }
}
section.centered {
  display: flex;
  justify-content: center;
}
.consignment_header {
  display: block;
  height: 40px;
  width: 100%;

  position: relative;
}
.consignment_header .bt_print {
  display: block;
  color: #fff;
  background: #004a7c;
  width: 120px;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: left;
  position: absolute;
  right: 60px;
  top: 0;
  i {
    position: relative;
    top: 3px;
  }
}
i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  &.adj1 {
    top: 0px;
    right: 0;
  }
}

.branchname_adjustment1 {
  position: relative;
}
.branchname_adjustment2 {
  position: absolute;
  top: 65px;
  left: 0;
  width: 335px;
}
p.adj_footer {
  text-align: center;
  padding: 0;
  text-transform: uppercase;
}

.comapny-name {
  margin-block: 6px;
}

</style>